<template>
<o-dropdown :allwaysOpened="props.allwaysOpened" :placement="dataLookupControl.placement" @beforeopen="onBeforeOpen" @beforeclose="onBeforeClose" ref="dropdown" @open="onOpen" :popperOptions="popperOptions">
    <template #default="scope">
        <input v-if="dataLookupControl.textInput" :ref="scope.target"  :readonly="dataLookupControl.readonly" v-bind="$attrs" v-model="wrappedModelValue">
        <slot v-else name="target" :target="scope.target" :open="scope.open" >
            <input v-model="wrappedModelValue" :id="props.id"  :readonly="dataLookupControl.readonly" v-bind="$attrs" :ref="scope.target" type="text">
        </slot>
    </template>
    <template #dropdown="scope">
        <div class="o365-data-lookup shadow card" 
            style="width: 400px;" tabindex="-1" 
            :style="{'width': dropdownWidth, 'min-height': lookupHeight+'px', 'max-height': lookupHeight+'px', 'max-width': maxWidthValue+'px'}" :ref="scope.container">
            <slot name="listContainer"> <slot></slot></slot>
        </div>
    </template>
</o-dropdown>
</template>
<script setup>
    import { ODropdown } from 'o365-ui-components';
    import { addEventListener } from 'o365-vue-utils';
    import { detectOverflow } from 'popper';
    import {ref,watch,computed,onMounted, inject} from 'vue';

    const dropdown = ref(null);
    const props = defineProps({
        dataLookupControl:{
            required:true
        },
        id:"",
        modelValue:{},
        value:{},
           
        allwaysOpened: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    const isEditorInGrid = inject('is-in-grid-cell', false);

    const lookupHeight = ref(props.dataLookupControl.height);
    //props.dataLookupControl.useLeftSizers = ref(false);
    props.dataLookupControl.useLeftSizers = false;

    const emit = defineEmits(['onbeforeopen', 'onbeforeclose', 'update:modelValue', 'onopen', 'beforeopen', 'beforeclose', 'open']);
    const wrappedModelValue = computed({
        get: () => props.modelValue ? props.modelValue : props.value,
        set: (value) => emit('update:modelValue', value),
    });
    const popperOptions = [
        {
            name: 'sizeLookupWithOverflow',
            enabled: true,
            phase: 'main',
            requiresIfExists: ['offset'],
            fn(ctx) {
                const overflow = detectOverflow(ctx.state, {});
                if (overflow.bottom > 0) {
                    const newHeight = lookupHeight.value - overflow.bottom;
                    lookupHeight.value = newHeight > 200 ? newHeight : 200;
                } else if (overflow.top > 0) {
                    const newHeight = lookupHeight.value - overflow.top;
                    lookupHeight.value = newHeight > 200 ? newHeight : 200;
                } else {
                    lookupHeight.value = props.dataLookupControl.height;
                }
            }
        }
    ];

    const dropdownWidth = computed(() => {
        const scrollbar = 20;
        
            return props.dataLookupControl.dataGridRef?.dataColumns.value?.centerWidth + props.dataLookupControl.dataGridRef?.dataColumns.value?.leftPinnedWidth + scrollbar + 10 + 'px';
    });
    const windowSize = ref(window.innerWidth);
    addEventListener(window, 'resize', () => {
        windowSize.value = window.innerWidth
    })
    const maxWidthValue = computed(() => {
        return props.dataLookupControl.maxWidth ?? windowSize.value;
    })

    watch(dropdownWidth, () => {
        window.requestAnimationFrame(() => {
            props.dataLookupControl.dataGridRef?.setViewPortWidth();
        });
    });

    watch(() => dropdown.value?.popperInstance?.state.placement, (placement) => {
        if (typeof placement === 'string' && placement.includes('end')) {
            // props.dataLookupControl.useLeftSizers.value = true;
            props.dataLookupControl.useLeftSizers = true;
        } else {
        // props.dataLookupControl.useLeftSizers.value = false;
        props.dataLookupControl.useLeftSizers = false;
        }
    });

    if (props.dataLookupControl && props.dataLookupControl.reloadOnWhereClauseChange) {
        watch(() => props.dataLookupControl.whereClause, (newClause) => {
            if (dropdown.value?.isOpen) {
                props.dataLookupControl.whereClause - newClause;
                props.dataLookupControl.load(true);
            }
        });
    }

    function onBeforeOpen() {
        if (props.multiselect && props.clearMultiselect) {
            props.dataLookupControl.dataObject.data.filter(x => x.isSelected).forEach(item => {
                item.isSelected = false;
            });
        }

        emit('onbeforeopen');
        emit('beforeopen');

        props.dataLookupControl.load();
    }

    function onBeforeClose() {
        props.dataLookupControl.navigation.removeHandler();
        if (props.dataLookupControl.focusInputAfterClose) {
            dropdown.value?.target?.focus();
        }
        emit('onbeforeclose');
        emit('beforeclose');
       
    };

    function onOpen() {
        props.dataLookupControl.dataGridRef?.setViewPortWidth();
        props.dataLookupControl.navigation.addHandler(dropdown.value.container);
        emit('onopen');
        emit('open');
    }

    onMounted(() => {
        props.dataLookupControl.dropdown = dropdown.value;
        if (!props.dataLookupControl.suppressClickEvent) {
            dropdown.value.target.onclick = () => {
                dropdown.value.open();
            }
        }

        if (props.dataLookupControl.openonfocus) {
            dropdown.value.target.onfocus = () => {
                dropdown.value.open();
            }
        }

        if (!props.dataLookupControl.suppressKeyboardEvents) {
            if (!dropdown.value.target?.addEventListener) { return; }
            dropdown.value.target.addEventListener('keydown', (e) => {
                if (e.key === 'F4') {
                    if (dropdown.value.isOpen) {
                        dropdown.value.close();
                    } else {
                        dropdown.value.open();
                    }
                }
            });
        }

        if (dropdown.value.target && dropdown.value.target.tagName.toLowerCase() === "input") {
            // Add chevron icon to input elements
            dropdown.value.target.classList.add("lookup-icon", "text-truncate");
        }
    });

    defineExpose({dropdown});

</script>
